import React , {Component} from 'react';
import {checkPermission} from "../../utils/methods";
import {LandingFooterPanelV3, Layout, NavigationBarV2, PricingPage} from "../../components";
import PropTypes from "prop-types";
class PricingPageHome extends Component{
  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <PricingPage  history={history}/>
        <LandingFooterPanelV3 isUser={authToken} />
      </Layout>
    );
  }
}

PricingPageHome.propTypes = {
  history: PropTypes.object
}
export default PricingPageHome;