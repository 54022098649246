import React, { Component } from "react";
import { checkPermission } from "../../utils/methods";
import {
  LandingFooterPanelV3,
  Layout,
  NavigationBarV2,
  RequestDemo,
} from "../../components";
import PropTypes from "prop-types";
class RequestDemoPage extends Component {
  componentDidMount() {
    // (function (h, o, t, j, a, r) {
    //   h.hj =
    //     h.hj ||
    //     function () {
    //       (h.hj.q = h.hj.q || []).push(arguments);
    //     };
    //   h._hjSettings = { hjid: 3425271, hjsv: 6 };
    //   a = o.getElementsByTagName("head")[0];
    //   r = o.createElement("script");
    //   r.async = 1;
    //   r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    //   a.appendChild(r);
    // })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }
  render() {
    const { history } = this.props;
    const authToken =
      checkPermission() && localStorage && localStorage.getItem("AUTH_TOKEN")
        ? true
        : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <RequestDemo history={history} />
        <LandingFooterPanelV3 isUser={authToken} />
      </Layout>
    );
  }
}

RequestDemoPage.propTypes = {
  history: PropTypes.object,
};
export default RequestDemoPage;
