import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ContentV2 } from '../../components';
// import ContentV3 from '../../components/ContentV3';

class Portal extends Component {

  componentDidMount() {
    const { location, history } = this.props;
    const pathNameArray = location.pathname.split('/');
    if(location.pathname.includes('code')) {
      location.pathname.split('=')[1].split('#')[0];
    }
    if(!pathNameArray[pathNameArray.length -1].includes('portal')) {
      history.push('/portal')
    }

  }

  render() {
    return (
      <ContentV2 history={this.props.history} check={this.props.check}/>
    )
  }
}

Portal.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  check: PropTypes.bool.isRequired
};

export default Portal;
