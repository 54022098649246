/* eslint-disable no-undef */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NavigationBarV2, LandingFooterPanelV2, Layout } from '../../components';

import { Section, Header, ContentWrapper, SmallHeader, ParagraphWrapper, LeftContainer, ListTitle} from '../TermsOfConditionV2/styles';

import TryAmigo from '../../components/AboutPageV2/TryAmigo';
import { checkPermission } from '../../utils/methods';
import {COMPANY_NAME} from '../../utils/config'
import LazyImage from '../../components/common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';
const consent = {
  content: [
    'By using the Website and/or Services, or allowing someone to use the Website and/or Services on Your behalf, You are consenting to Our collection, use, disclosure, transfer and storage in accordance with this Privacy Policy, subject to applicable Privacy Law, of any Personal Data or other information received by Us as a result of such use.'
  ]
};

const information = {
  content: [
    'When You interact with Us through the Services, We may collect Personal Data and other information about You, from You or other sources, including the information described below.  Again, use and protection of the same is subject to the terms of this Privacy Policy and applicable Privacy Law.'
  ]
}

const personal = {
  content: [
    'In order to operate the Services and conduct other business, We may collect: (a) “Personal Information” (i.e. information that could be used to contact You directly, such as your email address, physical address, phone number(s), credit/debit card information, or electronic payment identifiers/information); (b) “Demographic Information” (i.e. information that You submit, or that We collect, including, but not limited to, gender, age, browsing history information, searching history information, and registration history information); (c) “Geo-Locational Data” (i.e. information that can be used to identify Your physical location or servers used); (d) information about your physical exercise and other activities, and (e) “Meta-Data” (i.e. information which describes how, when and by whom a particular set of data was collected, and how the data is formatted). Collectively, the information in this Section 3 is called “Personal Data.” We use Personal Data that We collect only in ways that are permitted under Privacy Law and/or other applicable law, including without limitation as follows:'
  ]
}

const specific = {
  title: 'Specific Reason',
  content: `If You provide Personal Data for a certain reason, We may use the Personal Data in connection with the reason for which it was provided. For instance, if You contact us by e-mail, We will use the Personal Data You provide to answer Your question or resolve Your problem and will respond to the email address from which the contact came.${COMPANY_NAME === 'Training Amigo' ? '' : ' If you provide information about your physical exercise or other activity(ies) as part of the Woliba services, we may use the information/data to provide health assessments, health/clinical services,assistance with insurance or reduced insurance premiums, rewards and/or other services.'}`
}

const accessAndUse = {
  title: 'Access and Use',
  content: 'If You provide Personal Data in order to obtain access to or use of the Services or any functionality thereof, We will use Your Personal Data to provide You with access to or use of the Services or functionality and to monitor Your use of such Services or functionality. For example, If You provide information, including health-related information, that is sought or submitted to assist in Our provisions of Services, We may use and/or share the information in ways that are appropriate to provide the Services. This might include sharing information with employers, medical/clinical providers, insurers and other entities, including to provide You with reduced premiums, rewards or for other purposes.'
}

const internalBusiness = {
  title: 'Internal Business Purposes',
  content: 'We may use Your Personal Data for internal business purposes including without limitation to help us improve the content and functionality of the Services, to better understand Our users, to improve the Services, to protect against, identify or address wrongdoing, to enforce Our Terms of Use, to manage Your account and provide You with customer service, and to generally manage the Services and Our business.'
}

const marketing = {
  title: 'Marketing',
  content: 'We may use Your Personal Data to contact You in the future for Our marketing and advertising purposes, including without limitation to inform You about services or events We believe might be of interest to You, to develop promotional or marketing materials and provide those materials to You, and to display content and advertising on or off the Services that We believe might be of relevance to You.',
  extra: [
    'If We intend to use any Personal Data in any manner that is not consistent with this Privacy Policy, you will be informed of such anticipated use prior to or at the time at which the Personal Data is collected or We will obtain Your consent subsequent to such collection but prior to such use.'
  ]
}

const nonPersonal = {
  content: [
    'Because Non-Personal Data cannot be used to personally identify You, We may use such information for any lawful purpose. Woliba will only sell or rent Your personally identifying information, such as Your email address or telephone number, to third parties only to the extent permitted by applicable Privacy Laws. We do not disclose personally identifying information to anyone to whom we are not authorized to do so. Woliba also reserves the right to release information on its Users if compelled to do so by proper governmental or judicial authorities. Such disclosure shall be limited to only those entities to which Woliba is compelled to disclose the information. All Personal Data submitted by You will be disclosed to third parties only as described herein, as required by law, or as is required to protect the visitors, users, employees, and/or property of Woliba, or in the event of a merger or a sale or transfer of all or substantially all of the business, assets, or equity of Woliba to another company or entity.'
  ]
}

const informationTransfer = {
  content: [
    'We sell, rent or transfer Your Personal Data to third parties only with Your express consent or as otherwise described in this Privacy Policy. We view compliance with this Privacy Policy as a critical community principle. We understand clearly that Your information and Your trust is one of Our most important assets. We store and process Your information on computers that are protected by physical as well as technological security safeguards. If You object to Your Information being transferred or used as specified in this Privacy Policy, please do not use or access Our Website or Services.',
    'We may share aggregate information that does not identify You personally to from time to time, such as by publishing a report on trends in the usage of the Service. Please note that, while the aggregate data We provide will not identify You personally, in some instances third parties may be able to “de-anonymize” aggregate data to identify You personally based on additional information they have about You or that they receive from third parties. While We do not intend for third parties to de-anonymize the aggregate data that We provide, if You are concerned about potentially being personally identified by Our third party marketing partners, You should not use Our Website or Services.',
    'In addition, as We continue to develop Our business, We might sell subsidiaries or business units. In such transactions, customer information generally is one of the transferred business assets. Such information remains subject to applicable Privacy Law and will also be subject to this Privacy Policy and the policy of the buyer. In the event that We are acquired, or substantially all of Our assets are acquired, customer information may be one of the transferred assets.'
  ]
}

const creditAndStorage = {
  content: [
    'Your payment or banking information, if any, including credit card information, will be stored by a third party and may be used to charge applicable fees as specified in Our Terms of Use. We always endeavor to ensure that third party(ies) are PCI compliant and meet applicable industry standards for financial security.'
  ]
}

const materialChoose = {
  content: [
    'You may decide to share information, including Personal Data, on the Website or in connection with the Services, in which case We urge You to use good judgment. Do not share any information, including without limitation, Personal Data or other personal information, that You do not want third parties to obtain. Many internet connections are public and/or not secure, and other users using that same connection may have access to Your information; please make sure that You are using a secure internet connection. You assume all responsibility for any loss of privacy or other harm resulting from your voluntary disclosure of personal data and other personal information provided that the loss or harm doesn’t result from Our breach of the terms of this Privacy Policy or violation of Privacy Law.'
  ]
}

const cookiesAndPersonal = {
  content: [
    'Our Website may utilize cookies for anonymous user counting purposes as well as to track the content You view, and the nature of Your interactions with other users of the Website or Services each time You use the Website/Services, so that We can better serve You when You return to the Website/Service. We may also use cookies to help advertisers and publishers serve and manage ads provided on and through the Website/Services. A cookie is information that many websites save to a file on Your computer. A cookie by itself cannot be used by a website to procure Personal Data about a user. It cannot be used to read data stored on Your computer other than what has been voluntarily provided by You or is inside cookies provided by the same website. Most web browsers have a feature that allows You to selectively accept certain cookies or reject all of them.'
  ]
}

const webBeacons = {
  content: [
    'For our website, a “web beacon,” also sometimes called a pixel tag or transparent GIF, is an object that is embedded in a web page. It is usually invisible to You, but allows website operators to check whether You have viewed a particular web page or email communication. We may place web beacons on our website, and in emails We send to You.'
  ]
}

const thirdParties = {
  content: [
    'The Service may include third-party advertising, links to other websites, and other content from third-party businesses. These third-party sites, businesses, and advertisers, or advertising companies working on their behalf, may use Web beacons and personal identifiers to measure the effectiveness of their ads, personalize or optimize advertising content and to track users who link from their respective websites to Our Website and/or Services.',
    'We are not responsible for the privacy practices or the content of these third-party websites. You are encouraged to review the privacy policies of the different websites You visit.',
  ]
}

const waysOfInformation = {
  content: [
    'Without limitation of the foregoing, We may use Your information:'
  ]
}

const useOfInformation = [
  'To provide You with our Services and/or allow others to provide you with related services, such as health assessments, other health/clinical services, insurance, reduced insurance premiums, rewards or other services.',
  'To respond to Your requests or provide You with further information about Woliba or our Website/Services and new developments related thereto.',
  'To provide You with personalized content and ads.',
  'To give Our website and product developers information that may be useful for developing new features and products for customers.',
  'To improve the content and navigability of Our Website and Services.',
  'To alert You to new features, products, events and special offers.',
  'In connection with promotions that You may choose to participate in. Any information You submit in connection with promotions will be subject to the terms of the promotion, which may supersede this Privacy Policy. You will generally be given the option to consent to the use of Your information at the time You sign up for the promotion. You will not be required to participate in any promotion, contest or giveaway.',
  'If required to do so by law, court order or other government or law enforcement authority or regulatory agency; or if We believe in good faith that disclosing information is necessary or advisable, including, for example, to protect the rights, property, or safety of Woliba, You, users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.',
  'We may host discussions or other moderated forums for users who can help each other with technical and general questions. Topics for discussion may include technical support questions and problems, suggestions for improvement, or anything else that relates to the Website or Service. Please remember that any information that is disclosed in this area becomes public information and You should exercise caution when deciding to disclose any information.'
]

const informationRetention = {
  content: [
    'To preserve the integrity of Our databases, Our standard procedures call for Us to retain information submitted by users for an indefinite length of time. By making submissions, You consent to Our storage of all of Your information for an indefinite length of time, subject to your rights of removal/destruction under applicable Privacy Law. Thus, if required by Privacy Law and or other applicable law, We will nullify user information by erasing it from Our database. We will also respond to written user requests to nullify account information.'
  ]
}

const choiceOut = {
  content: [
    'Woliba provides users the opportunity to opt-out of receiving communications from Us and Our partners. Woliba also gives Users the option to remove their information from Our database, to not receive future communications or to no longer receive Our Service. This may not prevent third parties to whom information has been provided in accordance with this Privacy Policy from continuing to contact You.'
  ]
}

const sharePersonalInformation = {
  content: [
    'We may disclose personal information when We determine that such disclosure is necessary to comply with applicable law, to cooperate with law enforcement or to protect the interests or safety of Woliba or other visitors to the Service.'
  ]
}

const securityPerculation = {
  content: [
    'Your account is password-protected so that only You have access to Your account information.  In order to maintain this protection, do not give Your password to anyone. Woliba staff will never proactively reach out to You and ask for any personal account information, including Your password.  For our Website, You should sign out of Your account and the browser window every time you are done accessing the Website before someone else obtains access.  You should never share devices.  This will help protect Your information entered on public terminals from disclosure to third parties.',
    'We endeavor to utilize encryption whenever appropriate. Unfortunately, no data transmission can be guaranteed to be 100% secure. As a result, We cannot guarantee the security of any information You transmit, and You do so at Your own risk. If You have any further questions on this issue, please refer to Woliba Terms of Use. Woliba expressly disclaims any liability that may arise should any other individuals obtain the information You submit unless resulting from Woliba’s breach of this Privacy Policy or violation of applicable Privacy Law.'
  ]
}

const children = {
  content: [
    'The Service is not directed to children under the age of 13. We do not knowingly collect information from anyone We know to be under 13 years of age.'
  ]
}

const european = {
  content: [
    'Our business is located primarily in the United States. If You provide information to us, the information will be transferred out of the European Union (EU) to the United States. By providing personal information to us, You are consenting to its storage and use as described herein.'
  ]
}

const changingPrivacy = {
  content: [
    'If at any point We decide to use particular Personal Data in a manner materially different from that stated at the time it was collected, We will endeavor to notify users by way of an email but, notwithstanding the foregoing, Your continued use of the Website/Service will constitute Your acceptance of the new use terms. As such, We encourage You to review this Privacy Policy periodically. By using the Website/Service, You represent and warrant that You have read, understand and agree to all terms of this Privacy Policy.'
  ]
}

const retentionAndDeletion = {
  content: [
    'We may retain your Personal Data indefinitely, as described above, subject to applicable Privacy Law, including your rights of removal/destruction under applicable Privacy Law. You may close your account by contacting us. However, we may retain Personal Data and Non-Personal Data for an additional period as is permitted or required under applicable Privacy Law and/or other laws. Even if We delete your Personal Data it may persist on backup or archival media and other information systems controlled by Us or third parties.',
    'If you have any questions about Our Privacy Policy, Our Terms of Use or other aspects of Our website or Services, kindly refer to “Contacting Woliba” below.'
  ]
}

const changingPolicy = {
  content: [
    'If we decide to make material changes to Our Privacy Policy, we will notify You by placing a notice on Our Website, and You should periodically check the Website for such notices.'
  ]
}

const privacyLaw = {
  content:[
    'As used in this Privacy Policy, the term “Privacy Law” refers to the General Data Protection Regulation of the European Union, the California Consumer Privacy Act of 2018 ( “CCPA”), the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), Subtitle D of the HITECH Act (Title XIII of the American Recovery and Reinvestment Act of 2009), the Children’s Online Privacy Protection Act (“COPPA”), and other applicable data protection and privacy laws.'
  ]
}

const sideContent = [
  'Consent','Information We Collect', 'Personal Data', 'Non-Personal Data',
  'Information Transfer', 'Credit Card Storage', 'Material You Choose to Reveal',
  'Cookies/Personal Identifiers', 'Web Beacons', 'Third Parties’ Use of Web Beacons and Cookies',
  'Ways we May Use Information', 'Information Retention', 'Choice/Opt-Out', 'Special Cases in Which We Share Personal Information',
  'Our Security Precautions', 'Children', 'European Union Users', 'Changing our Privacy Policy for Previously Gathered Information',
  'Retention and Deletion', 'Changes to this policy', 'Contacting Woliba', 'Privacy Law'

]




class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.myref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("touchstart", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("touchstart", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.myref && this.myref.current && !this.myref.current.contains(e.target) && this.state.open === true ) {
      this.setState({open: false});
    }
  };

  renderTitle = (title, start) => (
    <ol start={start} className="marginTop">
      <li>
        <SmallHeader id = {title} fontSize="25px" disableWeight={1}>{title}</SmallHeader>
      </li>
    </ol>
  );

  renderSmallTitles = (title, content) => (
    <SmallHeader disableWeight={1} underline className="marginTop">
      <ParagraphWrapper>
        <span>{title}.   </span>
      </ParagraphWrapper>
      <ParagraphWrapper disableWeight={true}>
        {content}
      </ParagraphWrapper>
    </SmallHeader>
  );

  smallTitlesWithList = (arr) => (
    arr.map((obj, index) => (
      <ul key={index} >
        <LazyImage src={ImageUrl + "/images/PrivacyPolicy/listimage.png"} />
        <li className="listContainer">
          <div key={index} className="marginTop">
            <ParagraphWrapper disableWeight={1}>{obj}</ParagraphWrapper>
          </div>
        </li>
      </ul>
    ))
  );

  renderContent = (content) => (
    content.map((text, index) => (
      <div key={index} className="marginTop">
        <SmallHeader disableWeight={1}>
          <ParagraphWrapper disableWeight={true}>{text}</ParagraphWrapper>
        </SmallHeader>
      </div>
    ))
  );

  renderSideContent = () => (
    <ol>
      {
        sideContent.map((title, index) => (
          <li key={index}>
            <a href={`#${title}`}>{title}</a>
          </li>
        ))
      }
    </ol>
  );

  openMobileMenu = () => {
    this.setState({open: true})
  };


  closeNav = () => (
    this.setState({open: false})
  );

  render() {
    const { history, showFooterAndHeader} = this.props;
    const {open} =  this.state;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;
    return(
      <Layout>
        {showFooterAndHeader && <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />}
        <Section>
          {showFooterAndHeader &&
            <LeftContainer>
              <ListTitle>
                PRIVACY POLICY
              </ListTitle>
              {this.renderSideContent()}
            </LeftContainer>
          }
          {open ?
            <div id="mySidepanel" className="sidepanel" ref={this.myref}>
              <a className="closebtn" onClick={this.closeNav}>&times;</a>
              {this.renderSideContent()}
            </div>: null
          }
          {showFooterAndHeader &&
          <div className="buttoncontainer">
            <button className="openbtn" onClick={this.openMobileMenu}>
              <i className="fa fa-bars" />
            </button>
          </div>
          }
          <ContentWrapper>
            {showFooterAndHeader && <Header>
              Global Privacy
              {COMPANY_NAME === 'Training Amigo' ? <p>Effective date: April 24, 2020</p> : <p>Effective date: October 10, 2020</p>}
            </Header>}
            <SmallHeader disableWeight={1} >
              <ParagraphWrapper disableWeight={1} fontStyle={1}>
                {`Woliba, Inc. (“Woliba,” “Us,” “Company,” “We,” “Our,” “Its” and words of similar connotation) ${COMPANY_NAME === 'Training Amigo'? '' :'and the WOLIBA'}`}{COMPANY_NAME === 'Training Amigo'? '' :<span><sup>&#174;</sup></span>}{` and the services It provides to participants (${COMPANY_NAME === 'Training Amigo'? '' : 'collectively, "Woliba" and/or'} “Services”), mailing list subscribers and app users ( “Users,” “You,” “Your” and words of similar connotation) respects the privacy of its Users and has developed this Privacy Policy to demonstrate Its commitment to protecting Your privacy.  At Woliba, privacy is a top priority and We are firmly committed to guarding the confidence You have placed in Us and to use any information You provide responsibly and professionally to the extent described in this Privacy Policy. The purpose of this Privacy Policy is to notify You of about information of Yours that may be collected; how the information is used; with whom the information may be shared; what kind of security procedures are in place to protect the loss, misuse or alteration of Information under Our control; and how You can correct any inaccuracies in the information.  We encourage You to read this Privacy Policy carefully when using Our website`}
              </ParagraphWrapper>
              {COMPANY_NAME === 'Training Amigo'? <a href="https://www.trainingamigo.com/">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">www.TrainingAmigo.com</ParagraphWrapper>
              </a> : <a href="https://woliba.io/">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">www.woliba.io</ParagraphWrapper>
              </a>}
              <ParagraphWrapper disableWeight={1}>
                {'“Website”).   By using Our Website and/or Our Services, You are accepting the practices described in this Privacy Policy. Please carefully read this Privacy Policy, which includes important information regarding Your Personal Data and other information. Please note that this Privacy Policy may be considered by courts/tribunals of competent jurisdiction to be a legally binding agreement between You and Woliba. If You have any questions about Our privacy practices, please see the information on how to contact Us at the end of this Privacy Policy.'}
              </ParagraphWrapper>
            </SmallHeader>
            {this.renderTitle("Consent", 1)}
            { this.renderContent(consent.content)}
            {this.renderTitle("Information We Collect", 2)}
            { this.renderContent(information.content)}
            {this.renderTitle("Personal Data", 3)}
            { this.renderContent(personal.content)}
            { this.renderSmallTitles(specific.title, specific.content) }
            { this.renderSmallTitles(accessAndUse.title, accessAndUse.content) }
            { this.renderSmallTitles(internalBusiness.title, internalBusiness.content) }
            { this.renderSmallTitles(marketing.title, marketing.content) }
            { this.renderContent(marketing.extra)}
            {this.renderTitle('Non-Personal Data', 4)}
            { this.renderContent(nonPersonal.content)}
            {this.renderTitle('Information Transfer', 5)}
            { this.renderContent(informationTransfer.content)}
            {this.renderTitle('Credit Card Storage', 6)}
            { this.renderContent(creditAndStorage.content)}
            {this.renderTitle('Material You Choose to Reveal', 7)}
            { this.renderContent(materialChoose.content)}
            {this.renderTitle('Cookies/Personal Identifiers', 8)}
            { this.renderContent(cookiesAndPersonal.content)}
            {this.renderTitle('Web Beacons', 9)}
            { this.renderContent(webBeacons.content)}
            {this.renderTitle('Third Parties’ Use of Web Beacons and Cookies', 10)}
            { this.renderContent(thirdParties.content)}
            <div className="marginTop">
              <SmallHeader disableWeight={1}>
                <ParagraphWrapper disableWeight={1}>
                  {'Some third-party advertising companies may provide a mechanism to opt-out of their technology. For more information about the opt-out process, You may at the time of this writing visit the Network Advertising Initiative website currently available at'}
                </ParagraphWrapper>
                <a href="http://www.networkadvertising.org.">
                  <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">http:<span>/</span><span>/</span>www.networkadvertising.org</ParagraphWrapper>
                </a>
                <ParagraphWrapper>
                  {'.'} 
                </ParagraphWrapper>
              </SmallHeader>
            </div>
            {this.renderTitle('Ways we May Use Information', 11)}
            { this.renderContent(waysOfInformation.content)}
            {this.smallTitlesWithList(useOfInformation)}
            {this.renderTitle('Information Retention', 12)}
            { this.renderContent(informationRetention.content)}
            {this.renderTitle('Choice/Opt-Out', 13)}
            { this.renderContent(choiceOut.content)}
            {this.renderTitle('Special Cases in Which We Share Personal Information', 14)}
            { this.renderContent(sharePersonalInformation.content)}
            {this.renderTitle('Our Security Precautions', 15)}
            { this.renderContent(securityPerculation.content)}
            {this.renderTitle('Children', 16)}
            { this.renderContent(children.content)}
            {this.renderTitle('European Union Users', 17)}
            { this.renderContent(european.content)}
            {this.renderTitle('Changing our Privacy Policy for Previously Gathered Information', 18)}
            { this.renderContent(changingPrivacy.content)}
            {this.renderTitle('Retention and Deletion', 19)}
            { this.renderContent(retentionAndDeletion.content)}
            {this.renderTitle('Changes to this policy', 20)}
            { this.renderContent(changingPolicy.content)}  
            {this.renderTitle(`Contacting ${COMPANY_NAME}`, 21)}
            <div className="marginTop">
              <SmallHeader disableWeight={1}>
                <ParagraphWrapper disableWeight={1}>
                  {'If You have any questions about this Privacy Policy, the practices of Woliba or Your dealings with Woliba, You may contact Us at'}
                </ParagraphWrapper>
                {
                  COMPANY_NAME === 'Training Amigo' ?
                    <a href="mailto:Support@trainingamigo.com">
                      <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">Support@trainingamigo.com</ParagraphWrapper>
                    </a>:
                    <a href="mailto:Support@woliba.io">
                      <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">Support@woliba.io</ParagraphWrapper>
                    </a>
                }
                <ParagraphWrapper>
                  {'.'} 
                </ParagraphWrapper>
              </SmallHeader>
            </div>
            {this.renderTitle('Privacy Law', 22)}
            { this.renderContent(privacyLaw.content)}
          </ContentWrapper>
        </Section>
        {showFooterAndHeader && <TryAmigo
          history={history}
        />}
        {showFooterAndHeader && <LandingFooterPanelV2 />}
      </Layout>  
    )
  }
}

PrivacyPolicy.propTypes = {
  history: PropTypes.object.isRequired,
  showFooterAndHeader: PropTypes.bool
};

export default withRouter(PrivacyPolicy);