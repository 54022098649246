import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavigationBarV2, LandingFooterPanelV2, Layout, PricingDashboardV2 } from '../../components';

import { checkPermission } from '../../utils/methods';

class Pricing extends Component {
  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;   
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <PricingDashboardV2 history={history}/>
        <LandingFooterPanelV2 isUser={authToken} />
      </Layout>
    );
  }
}

Pricing.propTypes = {
  history: PropTypes.object
}

export default Pricing;